<template>
  <div class="w-full pt-2 pb-4 flex" style="justify-content: space-between">
    <div class="flex w-full mt-4">
      <i class="mr-3 pt-1.5 text-blue-800 pi pi-pencil"/>
      <p class="text-xl text-blue-800 font-bold">Editar Recepción</p>
    </div>
    <Button label="Atrás" icon="pi pi-arrow-left" class="p-button-raised" @click="backPage"/>
  </div>
  <Card class="w-full">
    <template #content>
      <div style="width: 100%">
        <div class="gap-1 grid text-xs grid-cols-2 md:grid-cols-4 lg:grid-cols-4 p-1">
          <div>
            <p class="text-xs">
              <strong>Código Cliente/Entidad: </strong>
            </p>
          </div>
          <div>
            <InputText class="p-inputtext-xs inputsHeader" v-model="dataRecepcion.CardCode" disabled/>
          </div>
          <div>
            <p class="text-xs xl:pl-10 md:pl-10 lg:pl-10">
              <strong>Fecha de Contabilización: </strong>
            </p>
          </div>
          <div>
            <input style="padding: 0.30rem;" class="border rounded-md p-inputtext-xs inputsHeader w-72"
                   v-model="dataRecepcion.TaxDate" disabled type="date">
          </div>
        </div>
        <div class="gap-1 grid text-xs grid-cols-2 md:grid-cols-4 lg:grid-cols-4 p-1">
          <div>
            <p class="text-xs">
              <strong>Nombre Cliente/Entidad: </strong>
            </p>
          </div>
          <div>
            <InputText class="p-inputtext-xs inputsHeader" v-model="dataRecepcion.CardName" disabled/>
          </div>
          <div>
            <p class="text-xs xl:pl-10 md:pl-10 lg:pl-10">
              <strong>Fecha de Vencimiento:</strong>
            </p>
          </div>
          <div>
            <input style="padding: 0.30rem;" class="border rounded-md p-inputtext-xs inputsHeader w-72"
                   v-model="dataRecepcion.TaxDate" disabled type="date">
          </div>
        </div>
        <div class="gap-1 grid text-xs grid-cols-2 md:grid-cols-4 lg:grid-cols-4 p-1">
          <div>
            <p class="text-xs">
              <strong>N. Factura Proveedor: </strong>
            </p>
          </div>
          <div>
            <InputText class="p-inputtext-xs inputsHeaderEditable" v-model="dataRecepcion.NumAtCard" :disabled="dataRecepcion.Area === 'COMPRAS'"/>
          </div>
          <div>
            <p class="text-xs xl:pl-10 md:pl-10 lg:pl-10">
              <strong>Fecha de Documento: </strong>
            </p>
          </div>
          <div>
            <input disabled style="padding: 0.30rem;" class="border rounded-md p-inputtext-xs inputsHeader w-72"
                   v-model="dataRecepcion.DocDate" type="date">
          </div>
        </div>
        <div class="flex text-xs p-1">
          <div>
            <p class="text-xs">
              <strong>Pedidos: </strong>
            </p>
          </div>
          <div class="pl-2">
            {{ dataRecepcion.numero_pedidos }}
          </div>
        </div>
        <div class="flex text-xs p-1">
          <div>
            <p class="text-xs">
              <strong>Cantidad de lineas: </strong>
            </p>
          </div>
          <div class="pl-2">
            {{ detailTemp.length  }}
          </div>
        </div>
      </div>
      <div style="width: 100%" class="p-2 mt-10">
        <div>
          <DataTable
            :value="detailTemp"
            class="p-datatable-sm text-xs"
            :paginator="true"
            :rows="50"
            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            :rowsPerPageOptions="[50,100,200]"
            currentPageReportTemplate="Mostrando {last} un total de {totalRecords}"
            showGridlines
            dataKey="id"
            responsiveLayout="scroll"
            v-model:filters="filters"
            :globalFilterFields="['nCompleto']"
            filterDisplay="row"
          >
            <template #empty>
              No hay datos para mostrar
            </template>
            <template #header>
              <div class="flex justify-between align-items-center">
                <div>
                  <Button icon="pi pi-print" label="Imprimir distribución" class="p-button-outlined p-button-secondary p-button-sm" @click="printPdf"/>
                </div>
                <div>
                  <span class="p-input-icon-left">
                    <i class="pi pi-search"/>
                    <InputText class="p-inputtext-xxs" inputClass="p-inputtext-xs text-xs" placeholder="Busqueda..."
                               @blur="searchCodeBars(CodeBars)" v-model="CodeBars"/>
                  </span>
                </div>
              </div>
            </template>
            <Column
              field="nCompleto"
              header="N° Artículo - Medicamento"
              headerClass="lg:text-xs"
              class="my-1 py-1 shadow-xs"
              filterField="nCompleto"
              :showFilterMatchModes="false"
              style="min-width: 20rem"
            >
              <!--              <template #filter="{filterModel}">-->
              <!--                <div class="mb-3 font-bold">Seleccionar medicamentos</div>-->
              <!--                <MultiSelect v-model="filterModel.value" :options="medicamentosList" optionLabel="Dscription"-->
              <!--                             optionValue="ItemCode" placeholder="Selección de medicamentos.." class="p-column-filter">-->
              <!--                  <template #option="slotProps">-->
              <!--                    <div class="p-multiselect-representative-option">-->
              <!--                      <span class="text-xs">{{ slotProps.option.ItemCode + ' - ' + slotProps.option.Dscription }}</span>-->
              <!--                    </div>-->
              <!--                  </template>-->
              <!--                </MultiSelect>-->
              <!--              </template>-->
              <template #filter="{filterModel,filterCallback}">
                <InputText type="text" v-model="filterModel.value" @keydown.enter="filterCallback()"
                           class="p-inputtext-xxs" v-tooltip.right.focus="'Presione enter para filtrar'"
                           inputClass="p-inputtext-xs text-xs"
                           :placeholder="`Buscar medicamentos... `"/>
              </template>
              <template #body="slotProps">
                <div class="h-full">
                  <div class="flex justify-between items-center my-1 pr-2 pl-2">
                    <div>
                      <span class="font-bold mr-2 ">Medicamento: </span><br>{{
                        slotProps.data.U_PHR_ItemCodSolicitado + ' - ' + slotProps.data.ItemNameOriginal
                      }}{{ '    ' }}
                    </div>
                    <div class="flex gap-2">
                      <Badge v-if="slotProps.data.mxSustituto" style="height: 1%;"
                             v-tooltip="{ value: `Codigo:  ${slotProps.data.ItemCode}   Med. Pedido: ${slotProps.data.ItemName}`, disabled: false }"
                             value="Sustituto" severity="danger" class="text-xs w-16"></Badge>
                      <Badge v-if="slotProps.data.ReqMarca" :value="`Req. marca` "
                             class=" bg-red-300 text-red-700 text-xxs h-4 capitalize"/>
                      <Badge v-if="slotProps.data.Controlado" value="Controlado"
                             class=" bg-green-300 text-green-700 text-xxs h-4 capitalize"></Badge>
                      <Badge v-if="slotProps.data.Regulado" value="Regulado"
                             class=" bg-blue-300 text-blue text-xxs h-4 capitalize"></Badge>
                      <Badge v-if="slotProps.data.cadenaFrio" value="Cadena Frio"
                             class="bg-blue-800 text-blue text-xxs h-4"></Badge>
                    </div>
                  </div>
                  <p class="text-xs p-2">
                    <span class="font-bold mr-2">Cod. Barras: </span><br>{{
                      slotProps.data.CodeBars ?? 'Medicamento sin código de barras asignado'
                    }}
                  </p>
                  <div class="text-xs p-2 lg:w-78 xl:w-78 sm:w-78"
                       v-if="!slotProps.data.medAdicional && slotProps.data.sustitutos.length">
                    <span class="font-bold mr-2">Sustitutos: </span><br>
                    <Dropdown
                      v-model="slotProps.data.ItemCode"
                      :options="slotProps.data.sustitutos"
                      class="rounded-xs border-gray-300 w-full text-xs p-inputtext-xs"
                      optionLabel="nomCompleto"
                      panelClass="text-xxs"
                      inputClass="p-inputtext-xxs text-xxs"
                      :showClear="true"
                      @change="MxSustituto(slotProps)"
                      optionValue="ItemCode"
                      :filter="true"
                      placeholder="Seleccione un sustituto..."/>
                  </div>
                  <div class="text-xs p-2 flex gap-2" v-if="slotProps.data.cadenaFrio">
                    <div>
                      <span class="font-bold mr-2">Temperatura: </span><br>
                      <InputNumber :disabled="dataRecepcion.Area === 'COMPRAS' && !slotProps.data.medAdicional" @blur="validateTemp($event,slotProps)"
                                   inputId="integeronly" suffix="℃" v-model="slotProps.data.temperatura"
                                   inputClass="p-inputtext-xxs w-12"/>
                    </div>
                    <div class="flex items-center"
                         v-if="slotProps.data.temperatura > slotProps.data.rangoTemperatura || slotProps.data.temperatura < 1">
                      <Badge value="Fuera de rango" severity="danger" class="text-xxs h-4"></Badge>
                    </div>
                  </div>
                  <div class="text-xs pl-2 pr-2"
                       v-if="slotProps.data.Quantity !== slotProps.data.lotes.reduce((acc, val) => acc + val.CantidadLote,0)">
                    <small class="color-red">Faltan cantidades de lotes por asignar, por favor verificar</small>
                  </div>
                  <!--                  <pre>{{slotProps.data}}</pre>-->
                  <div class="text-xs pl-2 pr-2" v-if="slotProps.data.distribucion.length === 0">
                    <small class="color-red">Faltan cantidades por distribuir, por favor verificar</small>
                  </div>
<!--                  <div class="text-xs pl-2 pr-2" v-if="((slotProps.data.receivedQuantity - slotProps.data.cantidadDefectuosa) > (slotProps.data.cantDistri - slotProps.data.cantidadDefectuosa))">-->
<!--                    <small class="color-red">Faltan bodegas por asignar en la distribución del medicamento, por favor revisar</small>-->
<!--                  </div>-->
                  <div class="text-xs pl-2 pr-2"
                       v-if="slotProps.data.cadenaFrio && slotProps.data.temperatura === null">
                    <small class="color-red">Por favor ingrese una temperatura para este medicamento.</small>
                  </div>
                </div>
              </template>
            </Column>
            <Column field="Quantity" header="Cantidad, Precio & Total" headerStyle="justify-content: left"
                    bodyStyle="text-align: left" style="min-width:13rem">
              <template #body="{data}">
                <div class="h-full w-full">
                  <p class="text-xs p-1 grid grid-cols-2">
                    <span class="font-bold mr-2">Num. pedido: </span>{{ data.BaseRef }}
                  </p>
                  <p class="text-xs p-1 grid grid-cols-2">
                    <span class="font-bold mr-2">Cant. solicitada: </span>{{ $h.formatNumber(data.U_PHR_Quantity) }}
                  </p>
                  <p class="text-xs p-1 grid grid-cols-2">
                    <span class="font-bold mr-2">Precio unitario: </span>{{
                      $h.formatCurrency(data.U_PHR_UnitPriceSolicitado)
                    }}
                  </p>
                  <p class="text-xs p-1  grid grid-cols-2">
                    <span class="font-bold mr-2">Precio total: </span>{{
                      $h.formatCurrency(data.U_PHR_Quantity * data.U_PHR_UnitPriceSolicitado)
                    }}
                  </p>
                  <p class="text-xs p-1  grid grid-cols-2"
                     v-show="$can('pharmasan.compras.logistica.recepcion-pedidos.listado-recepciones.area-compras')">
                    <span class="font-bold mr-2">Porc. Impuesto: </span>{{ $h.formatCurrency(data.taxRate) + '%' }}
                  </p>
                  <p class="text-xs p-1  grid grid-cols-2"
                     v-show="$can('pharmasan.compras.logistica.recepcion-pedidos.listado-recepciones.area-compras')">
                    <span
                      class="font-bold mr-2">Vlr. Impuesto: </span>{{
                      $h.formatCurrency((data.Quantity * data.UnitPrice) * data.taxRate / 100)
                    }}
                  </p>
                </div>
              </template>
            </Column>
            <Column field="" header="Confirmación" headerStyle="justify-content: left;"
                    bodyStyle="text-align: left" style="min-width:18rem">
              <template #body="slotProps">
                <div class="h-full">
                  <div class="text-xs p-1 grid grid-cols-2">
                    <span class="font-bold mr-2">Cantidad Factura: </span>
                    <InputNumber inputId="integeronly" :min="validateQuantity(slotProps)"
                                 inputClass="p-inputtext-xxs w-12" v-model="slotProps.data.Quantity"/>
                  </div>
                  <div class="text-xs p-1 grid grid-cols-2">
                    <span class="font-bold mr-2">
                      <i
                        v-show="slotProps.data.receivedQuantity > slotProps.data.Quantity || slotProps.data.receivedQuantity < slotProps.data.Quantity"
                        v-tooltip="{ value: slotProps.data.receivedQuantity > slotProps.data.Quantity ? 'Mayor Cant. Recibida' : 'Menor Cant. Recibida', disabled: false }"
                        :style="slotProps.data.receivedQuantity > slotProps.data.Quantity ? 'color:red' : 'color:#cd9712'"
                        class="pi pi-info-circle">
                      </i>
                      Cant. Recibida Bodega:
                    </span>
                    <InputNumber inputId="integeronly"
                                 :disabled="$can('pharmasan.compras.logistica.recepcion-pedidos.listado-recepciones.area-compras') && !slotProps.data.medAdicional"
                                 inputClass="p-inputtext-xxs w-12" v-model="slotProps.data.receivedQuantity"/>
                  </div>
                  <div class="text-xs p-1 grid grid-cols-2">
                    <span class="font-bold mr-2">Cantidad defectuosa: </span>
                    <InputNumber inputId="integeronly" :min="0"
                                 :disabled="$can('pharmasan.compras.logistica.recepcion-pedidos.listado-recepciones.area-compras') && !$can('pharmasan.compras.logistica.recepcion-pedidos.listado-recepciones.cambiar-ubicacion-compras') && !slotProps.data.medAdicional"
                                 inputClass="p-inputtext-xxs w-12" v-model="slotProps.data.cantidadDefectuosa"/>
                  </div>
                  <div class="text-xs p-1 grid grid-cols-2">
                    <span class="font-bold mr-2">Confirmar Precio: </span>
                    <InputNumber inputId="integeronly" :min="1" inputClass="p-inputtext-xxs w-12"
                                 v-model="slotProps.data.UnitPrice"/>
                  </div>
                  <div class="text-xs p-1  grid grid-cols-2"
                       v-show="$can('pharmasan.compras.logistica.recepcion-pedidos.listado-recepciones.area-compras')">
                    <span class="font-bold mr-2">% Descuento: </span>
                    <InputNumber v-model="slotProps.data.DiscountPercent" :step="0.25"
                                 inputClass="p-inputtext-xxs w-12" inputId="minmax"
                                 showButtons :min="0" :max="100"/>
                  </div>
                  <div class="text-xs p-1  grid grid-cols-2">
                    <span class="font-bold mr-2">Precio total confirmado: </span>{{
                      $h.formatCurrency((((slotProps.data.Quantity * slotProps.data.UnitPrice) + ((slotProps.data.taxRate * (slotProps.data.Quantity * slotProps.data.UnitPrice)) / 100)) - ((slotProps.data.Quantity * slotProps.data.UnitPrice) * slotProps.data.DiscountPercent / 100)))
                    }}
                  </div>
                </div>
              </template>
            </Column>
            <Column field="" header="Acciones" headerStyle="justify-content: left"
                    bodyStyle="text-align: center" style="min-width:8rem">
              <template #body="slotProps">
                <div class="gap-4 flex justify-center">
                  <Button v-tooltip.top="'Actualizar Lotes'"
                          class="p-button-text p-button-success p-1.5" @click="openModal(slotProps)">
                    <PackageIcon class="w-9/12"/>
                  </Button>
                  <Button v-tooltip.top="'Actualizar  Distribución'"
                          v-if="slotProps.data.idDetalle"
                          class="p-button-text p-button-success p-1.5" @click="openDistribuciones(slotProps)">
                    <menuIcon class="w-9/12"/>
                  </Button>
                  <Button icon="pi pi-save" v-tooltip.top="'Actualizar Linea'"
                          class="p-button-text p-button-success" @click="onSubmit(slotProps)"/>
                  <Button v-if="slotProps.data.medAdicional" icon="pi pi-trash" v-tooltip.top="'Quitar linea'"
                          iconClass="text-xl"
                          class="p-button-text p-button-danger" @click="deleteLine(slotProps)">
                  </Button>
                </div>
              </template>
            </Column>
          </DataTable>
        </div>
      </div>
    </template>
  </Card>
  <Card class="w-full mt-4">
    <template #content>
      <div class="w-full p-2 grid grid-cols-1 lg:grid-cols-3 gap-8">
        <div class="grid text-xs grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 p-1 gap-8 lg:col-span-2">
          <div class="flex flex-col">
            <p class="text-xs mb-2">
              <strong>Comentarios: </strong>
            </p>
            <Textarea
              class="p-inputtext-xs"
              disabled
              :autoResize="true"
              v-model="dataRecepcion.Comments"
              rows="5"
              cols="40"
            />
          </div>
          <div class="flex flex-col">
            <p class="text-xs mb-2">
              <strong>Entrada de diario: </strong>
            </p>
            <Textarea
              class="p-inputtext-xs"
              disabled
              :autoResize="true"
              v-model="dataRecepcion.JournalMemo"
              rows="5"
              cols="40"
            />
          </div>
        </div>
        <div class="text-xs lg:col-span-1">
          <div class="flex justify-between border-b-2 pb-3">
            <span class="text-gray-600">Sub Total Pedido: </span>
            <span class="">{{ $h.formatCurrency(subTotal) }}</span>
          </div>
          <div class=" flex justify-between my-3">
            <div>
              <span class="text-gray-600 mr-4">% Descuento:</span>
              <InputNumber @blur="asignarDescuentoDoc" inputId="integeronly" :min="0" :max="100"
                           class="p-inputtext-xs w-14" v-model="dataRecepcion.DiscountTotal"/>
            </div>
            <div>
              <span class="text-gray-600 mr-4">Total Descuento Documento:</span>
              <span class="">{{ $h.formatCurrency(descuentoDocumento) }}</span>
            </div>
          </div>
          <div class="flex justify-between my-3">
            <span class="text-gray-600">Total Descuento Lineas:</span>
            <span class="">{{ $h.formatCurrency(totalDescuento) }}</span>
          </div>
          <div class="flex justify-between my-3">
            <span class="text-gray-600">Total IVA:</span>
            <span class="">{{ $h.formatCurrency(totalSumIva) }}</span>
          </div>
          <div class="flex justify-between bg-blue-800 text-white rounded-lg p-2 text-sm">
            <span class="font-bold">Total Pedido:</span>
            <span
              class="">{{ $h.formatCurrency((subTotal - totalDescuento - descuentoDocumento) + totalSumIva) }}</span>
          </div>
        </div>
      </div>
    </template>
  </Card>
  <div>
    <modalEditarLotes ref="openModalEditarLotes" @refrescarLotes="getDataLotes"/>
  </div>
  <div>
    <modalCodeBars ref="openModalCodBars" @refrescar="getLoad"/>
  </div>
  <div>
    <modalDistribucion ref="openModalDistribuciones" @refrescarDistribucion="openDistribuciones"
                       @refrescarCantidad="updateCant" @validateBod="validarBodega"/>
  </div>
</template>
<script>
import { computed, defineAsyncComponent, ref, onMounted } from 'vue'
import recepcionPedidos from '@/apps/pharmasan/compras/logistica/store/recepcionPedidos'
import RecepcionPedidosService from '@/apps/pharmasan/compras/logistica/services/rcp-pedidos.service'
import modalLotes from './components/modalAsignarLotes.vue'
import Swal from 'sweetalert2'
import { useRoute, useRouter } from 'vue-router'
import { FilterMatchMode } from 'primevue/api'
import { useStore } from 'vuex'

export default {
  name: 'editarRecepcion',
  components: {
    modalEditarLotes: defineAsyncComponent(() => import('./components/modalEditarLotes.vue')),
    modalCodeBars: defineAsyncComponent(() => import('./components/asignarCodigoBarras.vue')),
    modalDistribucion: defineAsyncComponent(() => import('./components/modalDistribucion.vue'))
  },
  setup () {
    const bodegas = ref([])
    const _RecepcionPedidosService = ref(new RecepcionPedidosService())
    const router = useRouter()
    const route = useRoute()
    const openModalCodBars = ref()
    const detailRecepcion = ref([])
    const arrayUbicaciones = ref([])
    const allTaxes = ref([])
    const detailTemp = ref([])
    const uAdmin = ref(false)
    const CodeBars = ref(null)
    const store = useStore()
    const filters = ref({
      nCompleto: {
        value: null,
        matchMode: FilterMatchMode.CONTAINS
      }
    })
    const openModalEditarLotes = ref()
    const openModalDistribuciones = ref()
    const dataRecepcion = ref({})
    const numFact = ref()
    const totalDescuento = computed(() => {
      let result = 0
      result = detailRecepcion.value.reduce((total, current) => {
        return total + ((current.Quantity * current.UnitPrice) * current.DiscountPercent / 100)
      }, 0)
      return result
    })
    const subTotal = computed(() => {
      let total = 0
      detailRecepcion.value.forEach((a) => {
        total = total + (a.UnitPrice * a.Quantity)
      })
      return total
    })
    const descuentoDocumento = computed(() => {
      let result = 0
      result = (subTotal.value - totalDescuento.value) * (dataRecepcion.value.DiscountTotal / 100)
      return result
    })
    const MxSustituto = ({ data, index }) => {
      if (data.ItemCode) {
        const find = detailTemp.value[index].sustitutos.find((x) => x.ItemCode === data.ItemCode)
        detailTemp.value[index].U_PHR_ItemSustituto = find.Dscription
        detailTemp.value[index].U_PHR_ConversionSustituto = find.U_PHR_Conversion
        return
      }
      detailTemp.value[index].ItemCode = data.U_PHR_ItemCodSolicitado
      detailTemp.value[index].U_PHR_ItemSustituto = null
    }

    const totalSumIva = computed(() => {
      let result = 0
      result = detailRecepcion.value.reduce((total, current) => {
        return total + ((current.Quantity * current.UnitPrice) * current.taxRate / 100)
      }, 0)
      return result
    })
    /* const onChange = ({ index, data }) => {
      const find = allTaxes.value.find((x) => x.Code === data.TaxCode)
      detailRecepcion.value[index].totalIva = (detailRecepcion.value[index].Quantity * detailRecepcion.value[index].UnitPrice) * (find.Rate / 100)
    } */

    const validateQuantity = ({ index }) => {
      return detailRecepcion.value[index].lotes.reduce((total, current) => {
        return total + current.CantidadLote
      }, 0)
    }
    const validateTemp = (evnt, { index }) => {
      if (evnt.target.value) {
        const valueTemp = parseInt(evnt.target.value.replace(/[.,a-zA-Z$]/g, ''))
        if (valueTemp > detailTemp.value[index].rangoTemperatura || valueTemp < 1) {
          Swal.fire({
            icon: 'info',
            text: 'El medicamento ingresado esta por fuera del rango de temperatura.',
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3500,
            timerProgressBar: true
          })
        }
        detailTemp.value[index].temperatura = valueTemp
      }
    }
    const medicamentosList = computed(() => {
      const items = detailRecepcion.value.map((x) => {
        return {
          ItemCode: x.ItemCode,
          Dscription: x.ItemName
        }
      })
      return items
    })
    const validarBodega = ({ validate, line }) => {
      const index = detailTemp.value.findIndex((x) => x.LineNum === line.LineNum && x.BaseRef === line.BaseRef && x.ItemCode === line.ItemCode)
      detailTemp.value[index].validateBod = validate
    }
    const searchCodeBars = (codebars) => {
      const params = {
        pedidos: dataRecepcion.value.pedidosOriginales,
        codigoBarras: codebars || null,
        cardCode: dataRecepcion.value.CardCode
      }
      detailTemp.value = detailRecepcion.value
      if ([null, ''].includes(codebars)) {
        detailTemp.value = detailRecepcion.value
        return
      }
      /* inicialmente, busca en front si algún medicamento o sustituto posee el código de barras a buscar */
      const findList = detailTemp.value.filter((x) => x.CodeBars.toString() === codebars || x.sustitutos.some((a) => a.CodeBars && a.CodeBars.toString() === codebars))
      if (findList.length) {
        const medInList = detailTemp.value.filter(x => x.CodeBars.toString() === codebars)
        if (medInList.length) {
          CodeBars.value = null
          detailTemp.value = medInList
          return
        }
        const codeBarsInSustitutos = findList.filter(x => x.sustitutos.some((a) => a.CodeBars && a.CodeBars.toString() === codebars))
        if (codeBarsInSustitutos.length) {
          const detalle = codeBarsInSustitutos.map((x) => {
            return `<span> * Medicamento: ${x.U_PHR_ItemCodSolicitado} - ${x.ItemNameOriginal} Pedido: ${x.BaseRef}</span><br>`
          })
          Swal.fire({
            icon: 'info',
            title: '<span class="text-xl"> Medicamento encontrado en sustitutos </span>',
            width: 700,
            padding: '1rem 0rem 1rem 0rem',
            html: `
            <div class="text-base">
              <span>Los siguientes medicamentos tienen el código de barras <strong>${codebars}</strong> dentro de sus sustitutos:</span>
              <div>
                 ${detalle}
              </div>
              <div>
                <span>¿Desea seleccionar el medicamento como sustituto o agregarlo como uno nuevo?</span>
              </div>
            </div>
              `,
            showDenyButton: true,
            showCancelButton: true,
            focusCancel: true,
            confirmButtonText: 'Seleccionar sustituto',
            denyButtonText: 'Agregar medicamento',
            denyButtonColor: '#689F38',
            cancelButtonText: 'Cancelar'
          }).then((result) => {
            if (result.isConfirmed) {
              /* recorre la lista filtrada para en el caso de que el código de barras encontrado fue en un sustituto seleccionarlo automáticamente */
              for (const item of findList) {
                const findSustituto = item.sustitutos.find(x => x.CodeBars && x.CodeBars.toString() === codebars)
                if (findSustituto !== undefined) {
                  item.ItemCode = findSustituto.ItemCode
                }
              }
              CodeBars.value = null
            }
            if (result.isDenied) {
              const maximo = detailRecepcion.value.reduce((max, obj) => parseInt(obj.LineNum) > max ? parseInt(obj.LineNum) : max, detailRecepcion.value[0].LineNum) + 1
              const infoMdToAdd = codeBarsInSustitutos[0].sustitutos.find(a => a.CodeBars && a.CodeBars.toString() === codebars)
              detailRecepcion.value.push({
                LineNum: maximo,
                ItemCode: infoMdToAdd.ItemCode,
                Quantity: 0,
                U_PHR_Quantity: null,
                BaseRef: null,
                BaseEntry: null,
                visibility: false,
                CodeBars: infoMdToAdd.CodeBars,
                WhsName: 'BUCARAMANGA PISO 3',
                U_PHR_WarehouseCodeOri: null,
                WarehouseCode: 'BOD0002',
                nCompleto: `${infoMdToAdd.ItemCode} - ${infoMdToAdd.Dscription}`,
                ItemNameOriginal: infoMdToAdd.Dscription,
                ItemName: infoMdToAdd.Dscription,
                BaseLine: null,
                U_PHR_Conversion: infoMdToAdd.U_PHR_Conversion,
                rangoTemperatura: infoMdToAdd.rangoTemperatura,
                temperatura: infoMdToAdd.temperatura,
                cadenaFrio: infoMdToAdd.cadenaFrio,
                U_PHR_ConversionSustituto: null,
                UnitPrice: 0,
                U_PHR_UnitPriceSolicitado: null,
                DiscountPercent: 0,
                TaxCode: infoMdToAdd.TaxCodeAP,
                Controlado: infoMdToAdd.Controlado,
                Regulado: infoMdToAdd.Regulado,
                ReqMarca: false,
                mxSustituto: false,
                U_PHR_ItemCodSolicitado: infoMdToAdd.ItemCode,
                idDetalle: null,
                receivedQuantity: 0,
                taxRate: infoMdToAdd.taxRate,
                Usuario: null,
                cantidadDefectuosa: 0,
                cantDistri: 0,
                lotes: [],
                distribucion: [],
                sustitutos: [],
                medAdicional: true
              })
            }
          })
          CodeBars.value = null
          return
        }
        return
      }
      _RecepcionPedidosService.value.getLinesMx(params).then(({ data }) => {
        if (data.data.length && data.medOtroPedido) {
          const infoMd = data.data[0]
          Swal.fire({
            icon: 'info',
            title: 'Esta seguro?',
            html:
              `Se encontró el medicamento: <b>${infoMd.ItemCode} - ${infoMd.Dscription})</b>  en el pedido Nro. ${infoMd.DocNum} <br>
                   ${infoMd.NroRecepcion ? `Sin embargo, el medicamento se encuentra en la recepción sin procesar Nro. ${infoMd.NroRecepcion} <br>` : ''}
                  ¿Desea agregar este medicamento?`,
            showConfirmButton: true,
            showCancelButton: true
          }).then(({ value }) => {
            if (value) {
              if (infoMd.NroRecepcion) delete infoMd.NroRecepcion
              if (![...new Set(detailRecepcion.value.map(p => p.BaseRef))].includes(infoMd.DocNum)) {
                const pedidosArr = dataRecepcion.value.numero_pedidos.split(',')
                pedidosArr.push(infoMd.DocNum)
                dataRecepcion.value.numero_pedidos = pedidosArr.join(',')
              }
              const maximo = detailRecepcion.value.reduce((max, obj) => parseInt(obj.LineNum) > max ? parseInt(obj.LineNum) : max, detailRecepcion.value[0].LineNum) + 1
              detailRecepcion.value.push({
                LineNum: maximo,
                ItemCode: infoMd.ItemCode,
                Quantity: infoMd.Quantity,
                U_PHR_Quantity: infoMd.Quantity,
                BaseRef: infoMd.DocNum,
                BaseEntry: infoMd.DocEntry,
                visibility: false,
                CodeBars: infoMd.CodeBars,
                WhsName: 'BUCARAMANGA PISO 3',
                U_PHR_WarehouseCodeOri: infoMd.U_PHR_WarehouseCodeOri,
                WarehouseCode: infoMd.WhsCode,
                nCompleto: infoMd.nCompleto,
                ItemNameOriginal: infoMd.Dscription,
                ItemName: infoMd.Dscription,
                BaseLine: infoMd.BaseLine,
                U_PHR_Conversion: infoMd.U_PHR_Conversion,
                rangoTemperatura: infoMd.rangoTemperatura,
                temperatura: infoMd.temperatura,
                cadenaFrio: infoMd.cadenaFrio,
                U_PHR_ConversionSustituto: null,
                UnitPrice: infoMd.Price,
                U_PHR_UnitPriceSolicitado: infoMd.Price,
                DiscountPercent: 0,
                TaxCode: infoMd.TaxCode,
                Controlado: infoMd.Controlado,
                Regulado: infoMd.Regulado,
                ReqMarca: infoMd.ReqMarca,
                mxSustituto: false,
                U_PHR_ItemCodSolicitado: infoMd.ItemCode,
                idDetalle: null,
                receivedQuantity: 0,
                taxRate: infoMd.IVA,
                Usuario: null,
                cantidadDefectuosa: 0,
                cantDistri: 0,
                lotes: [],
                distribucion: [],
                sustitutos: [],
                medAdicional: true
              })
              CodeBars.value = null
            }
          })
          return
        }
        if (data.data.length && data.medAdicional) {
          const infoMed = data.data[0]
          Swal.fire({
            icon: 'info',
            title: 'Esta seguro?',
            html:
              `Se encontró el medicamento: <b>${infoMed.ItemCode} - ${infoMed.Dscription})</b> que no pertenece a ningún pedido inicial. <br>
                ${infoMed.NroRecepcion ? `Sin embargo, el medicamento se encuentra en la recepción sin procesar Nro. ${infoMed.NroRecepcion} <br>` : ''}
                ¿Desea agregar este medicamento?`,
            showConfirmButton: true,
            showCancelButton: true
          }).then((resp) => {
            if (resp.isConfirmed) {
              if (infoMed.NroRecepcion) delete infoMed.NroRecepcion
              const maximo = detailRecepcion.value.reduce((max, obj) => parseInt(obj.LineNum) > max ? parseInt(obj.LineNum) : max, detailRecepcion.value[0].LineNum) + 1
              detailRecepcion.value.push({
                LineNum: maximo,
                ItemCode: infoMed.ItemCode,
                Quantity: 0,
                U_PHR_Quantity: null,
                BaseRef: null,
                BaseEntry: null,
                visibility: false,
                CodeBars: infoMed.CodeBars,
                WhsName: 'BUCARAMANGA PISO 3',
                U_PHR_WarehouseCodeOri: null,
                WarehouseCode: 'BOD0002',
                nCompleto: `${infoMed.ItemCode} - ${infoMed.ItemName}`,
                ItemNameOriginal: infoMed.ItemName,
                ItemName: infoMed.ItemName,
                BaseLine: null,
                U_PHR_Conversion: infoMed.U_PHR_Conversion,
                rangoTemperatura: infoMed.rangoTemperatura,
                temperatura: infoMed.temperatura,
                cadenaFrio: infoMed.cadenaFrio,
                U_PHR_ConversionSustituto: null,
                UnitPrice: 0,
                U_PHR_UnitPriceSolicitado: null,
                DiscountPercent: 0,
                TaxCode: infoMed.TaxCode,
                Controlado: infoMed.Controlado,
                Regulado: infoMed.Regulado,
                ReqMarca: false,
                mxSustituto: false,
                U_PHR_ItemCodSolicitado: infoMed.U_PHR_ItemCodSolicitado,
                idDetalle: null,
                receivedQuantity: 0,
                taxRate: infoMed.taxRate,
                Usuario: null,
                cantidadDefectuosa: 0,
                cantDistri: 0,
                lotes: [],
                distribucion: [],
                sustitutos: [],
                medAdicional: true
              })
              CodeBars.value = null
            }
          })
          return
        }
        /* si no se agrega como medicamento adicional porque se encuentra en otro pedido o en OITM el medicamento
        es del mismo pedido, pero no se recepcionó el medicamento con anterioridad */
        if (data.data.length) {
          const infoMed = data.data[0]
          Swal.fire({
            icon: 'info',
            title: 'Esta seguro?',
            html:
              `Se encontró el medicamento: <b>${infoMed.ItemCode} - ${infoMed.Dscription})</b> en el pedido <strong>${infoMed.DocNum}</strong> el cual se encuentra en los <strong>pedidos originales </strong>. <br>
                ${infoMed.NroRecepcion ? `Sin embargo, el medicamento se encuentra en la recepción sin procesar Nro. ${infoMed.NroRecepcion} <br>` : ''}
                ¿Desea agregar este medicamento?`,
            showConfirmButton: true,
            showCancelButton: true
          }).then((resp) => {
            if (resp.isConfirmed) {
              if (infoMed.NroRecepcion) delete infoMed.NroRecepcion
              const maximo = detailRecepcion.value.reduce((max, obj) => parseInt(obj.LineNum) > max ? parseInt(obj.LineNum) : max, detailRecepcion.value[0].LineNum) + 1
              detailRecepcion.value.push({
                LineNum: maximo,
                ItemCode: infoMed.ItemCode,
                Quantity: infoMed.Quantity,
                U_PHR_Quantity: infoMed.U_PHR_Quantity,
                BaseRef: infoMed.DocNum,
                BaseEntry: infoMed.DocEntry,
                visibility: false,
                CodeBars: infoMed.CodeBars,
                WhsName: 'BUCARAMANGA PISO 3',
                U_PHR_WarehouseCodeOri: infoMed.U_PHR_WarehouseCodeOri,
                WarehouseCode: 'BOD0002',
                nCompleto: infoMed.nCompleto,
                ItemNameOriginal: infoMed.Dscription,
                ItemName: infoMed.Dscription,
                createdBy: null,
                BaseLine: infoMed.BaseLine,
                U_PHR_Conversion: infoMed.U_PHR_Conversion,
                rangoTemperatura: infoMed.rangoTemperatura,
                temperatura: infoMed.temperatura,
                cadenaFrio: infoMed.cadenaFrio,
                U_PHR_ConversionSustituto: null,
                UnitPrice: infoMed.Price,
                U_PHR_UnitPriceSolicitado: infoMed.U_PHR_UnitPriceSolicitado,
                DiscountPercent: 0,
                TaxCode: infoMed.TaxCode,
                Controlado: infoMed.Controlado,
                Regulado: infoMed.Regulado,
                ReqMarca: infoMed.ReqMarca,
                mxSustituto: false,
                U_PHR_ItemCodSolicitado: infoMed.U_PHR_ItemCodSolicitado,
                idDetalle: null,
                receivedQuantity: 0,
                taxRate: infoMed.IVA,
                Usuario: null,
                cantidadDefectuosa: 0,
                cantDistri: 0,
                lotes: [],
                distribucion: [],
                sustitutos: [],
                medAdicional: false
              })
              CodeBars.value = null
            }
          })
          return
        }
        if (params.codigoBarras) {
          Swal.fire({
            icon: 'question',
            title: 'Información',
            html:
              `No se encontro ningún medicamento asociado al código de barras: <strong>${codebars}</strong><br> ¿Desea asociarlo a un medicamento?`,
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: 'Si, asignar',
            cancelButtonText: 'No, cancelar'
          }).then(({ value }) => {
            if (value) {
              CodeBars.value = null
              openModalAsignarCodigos(codebars)
            }
          })
        }
      })
    }
    const updateCant = ({ cantidad, line }) => {
      const index = detailTemp.value.findIndex((x) => x.LineNum === line.LineNum && x.BaseRef === line.BaseRef && x.ItemCode === line.ItemCode)
      detailTemp.value[index].cantDistri = cantidad
      getLoad()
    }
    const getDataLotes = async (dataMx) => {
      await getLoad()
      const findIndex = detailTemp.value.findIndex((a) => `${a.LineNum}${a.U_PHR_ItemCodSolicitado}${a.BaseRef}${a.idDetalle}` === `${dataMx.LineNum}${dataMx.U_PHR_ItemCodSolicitado}${dataMx.BaseRef}${dataMx.idDetalle}`)
      const params = {
        detalle: detailTemp.value[findIndex],
        indice: findIndex,
        ubicaciones: arrayUbicaciones.value,
        permisoCarta: verificarPermiso('pharmasan.compras.logistica.recepcion-pedidos.listado-recepciones.carta-compromiso')
      }
      openModalEditarLotes.value.openModalEditarLotes(params)
    }
    const openDistribuciones = async ({ data }) => {
      let payload = {
        dataMx: data,
        detallado: [],
        bod: bodegas.value
      }
      if (![null, undefined, false, ''].includes(data.BaseLine) && ![null, undefined, false, ''].includes(data.BaseRef)) {
        const params = {
          LineNum: data.BaseLine,
          DocNum: data.BaseRef,
          idDetalle: data.idDetalle
        }
        await _RecepcionPedidosService.value.getDistribucionBorrador(params).then((resp) => {
          payload = {
            dataMx: data,
            detallado: resp.data,
            bod: bodegas.value,
            enca: dataRecepcion.value
          }
        })
      }
      openModalDistribuciones.value.openDistribucionesModal(payload)
    }
    const asignarDescuentoDoc = (val) => {
      if (val.target.value > 0) {
        Swal.fire({
          icon: 'question',
          title: '¿ Esta seguro ? ',
          text: `¿Desea agregar el ${val.target.value}% de descuento para este documento?`,
          showConfirmButton: true,
          showCancelButton: true,
          confirmButtonText: 'Si, asignar',
          cancelButtonText: 'No, cancelar'
        }).then((resp) => {
          if (resp.isConfirmed) {
            const params = {
              DiscountPercent: val.target.value
            }
            _RecepcionPedidosService.value.updateDiscount(route.params.DocEntry, params).then(({ data }) => {
              if (data.DocEntry) {
                Swal.fire({
                  icon: 'success',
                  title: 'Éxito ',
                  text: 'Descuento actualizado exitosamente.',
                  showConfirmButton: true,
                  confirmButtonText: 'Ok'
                })
              }
            })
          } else {
            dataRecepcion.value.DiscountTotal = 0
          }
        })
      }
    }
    const deleteLine = ({ index, data }) => {
      Swal.fire({
        icon: 'question',
        title: '¿Esta seguro?',
        html: `¿Desea eliminar el medicamento <strong>${data.U_PHR_ItemCodSolicitado} - ${data.ItemNameOriginal}</strong> ?`,
        confirmButtonText: 'Si',
        showCancelButton: true,
        cancelButtonText: 'No, cancelar'
      }).then(({ value }) => {
        if (value) {
          detailTemp.value.splice(index, 1)
          dataRecepcion.value.numero_pedidos = [...new Set(detailTemp.value.filter(a => ![null, ''].includes(a.BaseRef)).map(x => x.BaseRef))].join(',')
        }
      })
    }
    const openModalAsignarCodigos = (codBar) => {
      openModalCodBars.value.openModalCodeBars(codBar)
    }
    const backPage = () => {
      router.go(-1)
    }
    const getLoad = async () => {
      const user = store.getters['auth/user']
      uAdmin.value = user.admin
      await _RecepcionPedidosService.value.getRecepcionDetails({
        DocEntry: route.params.DocEntry,
        previewEntrada: false
      }).then(async ({ data }) => {
        if (['PROCESANDO', 'PROCESADO', 'ERROR'].includes(data.encabezado.estadoNombre)) {
          await Swal.fire({
            icon: 'warning',
            title: 'Advertencia',
            text: `No se puede visualizar la información de la recepción ya que se encuentra en estado ${data.encabezado.estadoNombre}`,
            timer: 10000,
            timerProgressBar: true,
            showConfirmButton: false
          }).then(() => {
            router.push({ name: 'pharmasan.compras.logistica.recepcion-pedidos.listar-pedidos' })
          })
          return
        }
        dataRecepcion.value = data.encabezado
        detailRecepcion.value = data.detalle
        detailTemp.value = data.detalle
      })

      if (!uAdmin.value || !verificarPermiso('pharmasan.compras.logistica.recepcion-pedidos.administrador-modulo-recepcion-pedidos')) {
        if (!verificarPermiso('pharmasan.compras.logistica.recepcion-pedidos.listado-recepciones.area-bodega')) {
          if (dataRecepcion.value.IdUsuarioAsignado !== user.user_id) {
            Swal.fire({
              icon: 'warning',
              title: 'Advertencia',
              text: 'Esta recepción ya se encuentra asignada a un usuario diferente, por favor verificar',
              showConfirmButton: false,
              timer: 6000,
              timerProgressBar: true
            }).then((resp) => {
              router.push({ name: 'pharmasan.compras.logistica.recepcion-pedidos.listar-pedidos' })
            })
          }
        }
        if (verificarPermiso('pharmasan.compras.logistica.recepcion-pedidos.listado-recepciones.area-bodega') && dataRecepcion.value.Area === 'COMPRAS' && !verificarPermiso('pharmasan.compras.logistica.recepcion-pedidos.administrador-modulo-recepcion-pedidos')) {
          Swal.fire({
            icon: 'warning',
            title: 'Advertencia',
            text: 'Usted no está autorizado para editar esta recepción',
            showConfirmButton: false,
            timer: 6000,
            timerProgressBar: true
          }).then((resp) => {
            router.push({ name: 'pharmasan.compras.logistica.recepcion-pedidos.listar-pedidos' })
          })
        } else if (verificarPermiso('pharmasan.compras.logistica.recepcion-pedidos.listado-recepciones.area-compras') && dataRecepcion.value.Area === 'BODEGA' && !verificarPermiso('pharmasan.compras.logistica.recepcion-pedidos.administrador-modulo-recepcion-pedidos')) {
          Swal.fire({
            icon: 'warning',
            title: 'Advertencia',
            text: 'Usted no está autorizado para editar esta recepción',
            showConfirmButton: false,
            timer: 6000,
            timerProgressBar: true
          }).then((resp) => {
            router.push({ name: 'pharmasan.compras.logistica.recepcion-pedidos.listar-pedidos' })
          })
        }
      }
    }
    const openModal = async ({ index, data }) => {
      const params = {
        detalle: data,
        indice: index,
        ubicaciones: arrayUbicaciones.value,
        permisoCarta: !verificarPermiso('pharmasan.compras.logistica.recepcion-pedidos.listado-recepciones.carta-compromiso')
      }
      if (data.Quantity > 0 && data.UnitPrice > 0) {
        openModalEditarLotes.value.openModalEditarLotes(params)
      } else {
        Swal.fire({
          icon: 'info',
          text: 'La cantidad recibida y el precio confirmado del medicamento debe ser mayor a 0.',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
          timerProgressBar: true
        })
      }
    }
    const getBodegas = () => {
      _RecepcionPedidosService.value.getBodegas().then(({ data }) => {
        bodegas.value = data
      })
    }
    const verificarPermiso = (permiso) => {
      const user = store.getters['auth/user']
      if (!user.admin) {
        const permissions = store.getters['auth/getPermissions']
        if (permissions.some(a => a === permiso)) return true
        return false
      }
      return true
    }
    const editLine = ({ index, data }) => {
      const params = {
        index: index,
        data: data
      }
      recepcionPedidos.commit('onEditLine', params)
    }
    const printPdf = () => {
      if (detailTemp.value.some((x) => x.distribucion.length === 0)) {
        Swal.fire({
          icon: 'info',
          title: 'Información',
          text: 'Hay lineas sin distribución, por favor validar.',
          showConfirmButton: false,
          timer: 1500,
          timerProgressBar: true
        })
        return
      }
      if (detailTemp.value.some((x) => (x.receivedQuantity - x.cantidadDefectuosa) !== (x.cantDistri - x.cantidadDefectuosa))) {
        Swal.fire({
          icon: 'info',
          title: 'Información',
          text: 'Hay inconsistencias en las cantidades de la distribución, por favor revisar',
          showConfirmButton: false,
          timer: 1500,
          timerProgressBar: true
        })
        return
      }

      const distribucion = detailTemp.value.flatMap(element => element.distribucion)
      const datos = distribucion.map((x) => {
        const find = detailTemp.value.find((a) => a.idDetalle === x.idDetalleRecepcion)
        return {
          nit: '900249425-1',
          direccion: 'Calle 32 # 30-34',
          telefono: '6960254-6960255',
          DocNum: dataRecepcion.value.DocEntry,
          CardName: `${dataRecepcion.value.CardCode} - ${dataRecepcion.value.CardName}`,
          DocDate: dataRecepcion.value.DocDate,
          ItemCode: `${find.ItemCode} - ${find.ItemName}`,
          U_PHR_RegSanitario: find.U_PHR_RegSanitario,
          nomBodega: `${x.CodBodega} - ${x.NombreBodega}`,
          Quantity: x.cantidad
        }
      })
      _RecepcionPedidosService.value.imprimirPdfDistribucion(dataRecepcion.value.DocEntry, 'all', datos)
    }
    const ubicaciones = () => {
      _RecepcionPedidosService.value.getUbicaciones().then(({ data }) => {
        arrayUbicaciones.value = data
      })
    }
    const onSubmit = ({ index }) => {
      const detalle = detailRecepcion.value[index]
      if (detalle.Quantity !== detalle.lotes.reduce((acc, val) => acc + val.CantidadLote, 0) || !detalle.lotes.length) {
        Swal.fire({
          icon: 'info',
          title: 'Información',
          text: 'No se han asignado los lotes, hay cantidades sin lotes asignados o faltan cantidades por distribuir, por favor realice la asignación',
          showCancelButton: false
        })
        return
      }
      if (detalle.idDetalle && detalle.Quantity !== detalle.cantDistri && dataRecepcion.value.Area !== 'BODEGA') {
        Swal.fire({
          icon: 'info',
          title: 'Información',
          text: 'Hay inconsistencias en las cantidades asignadas, por favor revisar',
          showCancelButton: false
        })
        return
      }
      if (detalle.validateBod || ((detalle.receivedQuantity - detalle.cantidadDefectuosa) > detalle.cantDistri)) {
        Swal.fire({
          icon: 'info',
          title: 'Información',
          text: 'Faltan bodegas por asignar en la distribución del medicamento, por favor revisar',
          showCancelButton: false
        })
        return
      }
      if (detalle.cadenaFrio && !detalle.temperatura) {
        Swal.fire({
          icon: 'info',
          text: 'Debe ingresar la temperatura en los medicamentos con cadena de frio, por favor revisar',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
          timerProgressBar: true
        })
        return
      }
      Swal.fire({
        icon: 'question',
        title: '¿ Esta seguro ? ',
        text: `Desea guardar los cambios sobre la linea No ${index}`,
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: 'Si, actualizar',
        cancelButtonText: 'No, cancelar'
      }).then(({ value }) => {
        if (value) {
          const params = {
            numero_pedidos: dataRecepcion.value.numero_pedidos,
            id: detalle.idDetalle ?? null,
            DocEntry: route.params.DocEntry,
            ItemCode: detalle.ItemCode,
            ItemName: [null, undefined].includes(detalle.U_PHR_ItemSustituto) ? detalle.ItemNameOriginal : detalle.U_PHR_ItemSustituto,
            Quantity: detalle.Quantity,
            U_PHR_Quantity: detalle.U_PHR_Quantity ? detalle.U_PHR_Quantity : null,
            U_PHR_UnitPriceSolicitado: detalle.U_PHR_UnitPriceSolicitado ? detalle.U_PHR_UnitPriceSolicitado : null,
            BaseLine: detalle.BaseLine !== null ? detalle.BaseLine : null,
            BaseEntry: detalle.BaseEntry !== null ? detalle.BaseEntry : null,
            BaseRef: detalle.BaseRef !== null ? detalle.BaseRef : null,
            CodeBars: detalle.CodeBars,
            U_PHR_WarehouseCodeOri: 'BOD0002',
            WarehouseCode: 'BOD0002',
            U_PHR_ItemCodSolicitado: detalle.U_PHR_ItemCodSolicitado,
            UnitPrice: detalle.UnitPrice,
            DiscountPercent: detalle.DiscountPercent,
            TaxCode: detalle.TaxCode,
            cantidadDefectuosa: detalle.cantidadDefectuosa,
            receivedQuantity: detalle.receivedQuantity,
            temperatura: detalle.temperatura,
            U_PHR_MedAdicional: !!detalle.medAdicional,
            LineNum: detalle.LineNum,
            lotes: detalle.lotes.map((x) => {
              return {
                id: x.id ?? null,
                DistNumber: x.DistNumber,
                ExpDate: x.ExpDate,
                Quantity: x.CantidadLote,
                id_detalle: detalle.idDetalle,
                AbsEntry: x.AbsEntry,
                cartaPdf: x.cartaPdf,
                novedades: x.novedades.map((a) => {
                  return {
                    DocEntryRecepcion: route.params.DocEntry,
                    idDetalleRecepcion: detalle.idDetalle,
                    absEntryLote: x.AbsEntry,
                    DistNumber: x.DistNumber,
                    ExpDate: x.ExpDate,
                    Quantity: x.CantidadLote,
                    idNovedad: a.tipo,
                    codigoDetalleNov: a.detalleNov,
                    observacion: a.observacion,
                    novId: a.novId
                  }
                })
              }
            })
          }
          _RecepcionPedidosService.value.updateLineaRecepcion(params).then(({ data }) => {
            if (data.id) {
              Swal.fire({
                icon: 'success',
                title: 'Éxito',
                text: 'Se actualizó la linea correctamente',
                showConfirmButton: true
              }).then((response) => {
                if (response.isConfirmed) {
                  getLoad()
                }
              })
            } else {
              Swal.fire({
                icon: 'warning',
                title: 'Advertencia',
                text: data.message,
                showConfirmButton: true
              })
            }
          })
        }
      })
    }

    onMounted(async () => {
      await getBodegas()
      await getLoad()
      await ubicaciones()
    })
    return {
      openDistribuciones,
      getLoad,
      detailTemp,
      dataRecepcion,
      detailRecepcion,
      openModalEditarLotes,
      modalLotes,
      openModal,
      onSubmit,
      editLine,
      totalDescuento,
      subTotal,
      filters,
      medicamentosList,
      searchCodeBars,
      CodeBars,
      numFact,
      backPage,
      route,
      allTaxes,
      validateQuantity,
      // onChange,
      totalSumIva,
      openModalCodBars,
      descuentoDocumento,
      asignarDescuentoDoc,
      ubicaciones,
      // validateCant
      deleteLine,
      MxSustituto,
      getDataLotes,
      uAdmin,
      openModalDistribuciones,
      getBodegas,
      updateCant,
      validarBodega,
      validateTemp,
      printPdf
    }
  }
}
</script>

<style scoped>
.header {
  display: grid;
  box-shadow: 3px 3px 3px 4px whitesmoke;
}

.inputsHeader {
  background-color: #eef1f5;
  width: 100%;
  height: 100%;
}

.inputsHeaderEditable {
  background-color: #fff;
  width: 100%;
  height: 100%;
}

.p-card.p-card-body {
  padding-bottom: 0.2rem;
  padding-top: 0.2rem;
  padding-right: 1rem;
  padding-left: 1rem;
}

.footerModal {
  display: flex;
  padding: 10px;
}

::v-deep(.pi) {
  font-size: 0.8rem;
}

::v-deep(.p-column-header-content) {
  justify-content: center;
}

.floating-button {
  position: fixed !important;
  bottom: 35px;
  right: 40px;
  z-index: 1050;
}

::v-deep(.p-inputnumber) {
  width: 2rem;
}

::v-deep(.p-tooltip) {
  width: 10rem;
}

.text-xs {
  font-size: 0.70rem;
}

:global(.swal2-container) {
  z-index: 1000000 !important
}
</style>
