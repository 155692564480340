import { createStore, useStore } from 'vuex'

const storeRecepcionPedidos = {
  state: {
    encabezado: {},
    medicamentos: [],
    bodegas: [],
    DocumentLines: [],
    lotes: [],
    DocEntry: null,
    permiso: false
  },
  getters: {
    _medicamentos: state => state.medicamentos,
    _encabezado: state => state.encabezado,
    _bodegas: state => state.bodegas,
    _DocumentLines: state => state.DocumentLines,
    _lotes: state => state.lotes,
    _DocEntry: state => state.DocEntry,
    _permiso: state => state.permiso
  },
  mutations: {
    onSetEncabezado (state, payload) {
      state.encabezado = payload
    },
    onSetLinesMx (state, payload) {
      state.medicamentos = payload
    },
    onSetBodegas (state, payload) {
      state.bodegas = payload
    },
    onLoteEditable (state, payload) {
      const index = state.lotes.findIndex((a) => `${a.LineNum}${a.U_PHR_ItemCodSolicitado}${a.BaseRef}${a.lote}${a.cantidad}${a.AbsEntry}` === `${payload.LineNum}${payload.U_PHR_ItemCodSolicitado}${payload.BaseRef}${payload.lote}${payload.cantidad}${payload.AbsEntry}`)
      state.lotes[index].visible = false
    },
    onDeleteLote (state, payload) {
      const index = state.lotes.findIndex((a) => `${a.LineNum}${a.U_PHR_ItemCodSolicitado}${a.BaseRef}${a.lote}${a.cantidad}${a.AbsEntry}` === `${payload.LineNum}${payload.U_PHR_ItemCodSolicitado}${payload.BaseRef}${payload.lote}${payload.cantidad}${payload.AbsEntry}`)
      state.lotes.splice(index, 1)

      const indexDocLines = state.DocumentLines.findIndex((a) => `${a.LineNum}${a.U_PHR_ItemCodSolicitado}${a.BaseRef}` === `${payload.LineNum}${payload.U_PHR_ItemCodSolicitado}${payload.BaseRef}`)
      if (indexDocLines !== -1) {
        const indexDocLinesLotes = state.DocumentLines[indexDocLines].lotes.findIndex((l) => `${l.lote}${l.cantidad}${l.AbsEntry}` === `${payload.lote}${payload.cantidad}${payload.AbsEntry}`)
        state.DocumentLines[indexDocLines].lotes.splice(indexDocLinesLotes, 1)
      }
    },
    addDocumentLine (state, payload) {
      state.DocumentLines.push(payload)
    },
    onSetDistribucionBod (state, payload) {
      const index = state.DocumentLines.findIndex((x) => `${x.LineNum}${x.BaseEntry}${x.ItemCode}${x.BaseRef}` === `${payload.linea.LineNum}${payload.linea.DocEntry}${payload.linea.ItemCode}${payload.linea.DocNum}`)
      // esta es la dstribución a guardar
      state.DocumentLines[index].distribucion = payload.cargaUtil
    },
    changeItemCodeDocumentLine (state, payload) {
      const indexDocLines = state.DocumentLines.findIndex((a) => `${a.LineNum}${a.U_PHR_ItemCodSolicitado}${a.BaseRef}` === `${payload.LineNum}${payload.U_PHR_ItemCodSolicitado}${payload.DocNum}`)
      if (indexDocLines !== -1) {
        state.DocumentLines[indexDocLines].ItemCode = payload.ItemCode
        state.DocumentLines[indexDocLines].ItemName = payload.U_PHR_ItemSustituto
      }
    },
    onDeleteDocumentLine (state, LineNum) {
      state.DocumentLines = state.DocumentLines.filter((a) => a.LineNum !== LineNum)
    },
    onDeleteLotesMd (state, LineNum) {
      state.lotes = state.lotes.filter((a) => a.LineNum !== LineNum)
    },
    onResetRowEdit (state) {
      state.lotes.forEach((element) => {
        element.visible = true
      })
    },
    onSetDataLotes (state, payload) {
      state.lotes.push(payload)
    },
    onReset (state) {
      state.medicamentos = []
      state.DocumentLines = []
      state.lotes = []
      state.bodegas = []
    },
    onCloseLine (state, data) {
      const index = state.medicamentos.data.findIndex((a) => `${a.LineNum}${a.U_PHR_ItemCodSolicitado}${a.DocNum}` === `${data.LineNum}${data.U_PHR_ItemCodSolicitado}${data.BaseRef}`)
      if (state.medicamentos.data[index]) {
        state.medicamentos.data[index].lineaConfirmada = true
      }
    },
    onEditLine (state, payload) {
      state.medicamentos.data[payload.index].lineaConfirmada = false
      if (payload.data.medAdicional) {
        state.DocumentLines = state.DocumentLines.filter((a) => a.medAdicional !== true && a.ItemCode === payload.data.ItemCode)
      } else {
        state.DocumentLines = state.DocumentLines.filter((a) => `${a.BaseLine}${a.ItemCode}${a.BaseRef}` !== `${payload.data.BaseLine}${payload.data.ItemCode}${payload.data.DocNum}`)
      }
    },
    onUpdateTemp (state, payload) {
      const index = state.DocumentLines.findIndex((a) => `${a.LineNum}${a.U_PHR_ItemCodSolicitado}${a.DocNum}` === `${payload.LineNum}${payload.U_PHR_ItemCodSolicitado}${payload.BaseRef}`)
      if (state.DocumentLines[index]) {
        state.DocumentLines[index].temperatura = payload.temperatura
      }
    },
    onAddMx (state, payload) {
      state.medicamentos.data.push(payload)
    },
    onSetDocEntry (state, payload) {
      state.DocEntry = payload
    },
    addNovedad (state, payload) {
      const indexLote = state.lotes.findIndex(l => `${l.LineNum}${l.U_PHR_ItemCodSolicitado}${l.BaseRef}${l.lote}${l.AbsEntry}${l.SL1Abs}` === `${payload.LineNum}${payload.U_PHR_ItemCodSolicitado}${payload.DocNum}${payload.DistNumber}${payload.AbsEntry}${payload.SL1Abs}`)
      if (!state.lotes[indexLote].novedades) {
        state.lotes[indexLote].novedades = []
      }
      state.lotes[indexLote].novedades.push(payload)
    },
    saveNovedadesInDocLines (state, payload) {
      if (state.DocumentLines[payload.index].novedades === undefined) {
        state.DocumentLines[payload.index].novedades = {}
      }
      state.DocumentLines[payload.index].novedades = payload.data
    },
    updateCartaConfirm (state, payload) {
      const findIndex = state.lotes.findIndex((x) => `${x.lote}${x.cantidad}${x.expDate}${x.AbsEntry}${x.SL1Abs}` === `${payload.data.lote}${payload.data.cantidad}${payload.data.expDate}${payload.data.AbsEntry}${payload.data.SL1Abs}`)
      state.lotes[findIndex].cartaPdf = payload.file
      state.lotes[findIndex].NombreCartaPdf = payload.fileName
      state.lotes[findIndex].alertaExpDate = false
    },
    removeCarta (state, payload) {
      const findIndex = state.lotes.findIndex((a) => `${a.lote}${a.cantidad}${a.expDate}${a.AbsEntry}${a.SL1Abs}` === `${payload.lote}${payload.cantidad}${payload.expDate}${payload.AbsEntry}${payload.SL1Abs}`)
      state.lotes[findIndex].cartaPdf = ''
      state.lotes[findIndex].NombreCartaPdf = ''
      state.lotes[findIndex].alertaExpDate = true
    },
    deleteNovedad (state, { indexStore, indexNovedad }) {
      state.lotes[indexStore].novedades.splice(indexNovedad, 1)
    }
  },
  actions: {
    onValidatePermiso (state, permiso) {
      const store = useStore()
      const user = store.getters['auth/user']
      if (!user.admin) {
        const permissions = store.getters['auth/getPermissions']
        return permissions.some(a => a === permiso)
      }
      return true
    },
    async onSetDocumentLines (store, payload) {
      const filter = store.state.DocumentLines.filter((a) => `${a.LineNum}${a.U_PHR_ItemCodSolicitado}${a.BaseRef}` === `${payload.LineNum}${payload.U_PHR_ItemCodSolicitado}${payload.BaseRef}`)
      if (filter.length) {
        store.state.DocumentLines = store.state.DocumentLines.filter((a) => `${a.LineNum}${a.U_PHR_ItemCodSolicitado}${a.BaseRef}` !== `${payload.LineNum}${payload.U_PHR_ItemCodSolicitado}${payload.BaseRef}`)
      }
      store.commit('addDocumentLine', payload)
    }
  }
}
const store = createStore(storeRecepcionPedidos)
export default {
  namespaced: true,
  ...store
}
